/* body {
    height: 100vh;
    display: grid;
    place-items: center;
  } */

.container {
  display: flex;
  justify-content: center;
}
.loader {
  position: absolute;
  width: 300px;
  height: 200px;
  z-index: 15;
  top: 50%;
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  border: 10px solid orange;
  border-top-color: #002147;
  box-sizing: border-box;
  background: transparent;
  animation: loading 1s linear infinite;
}

@media screen and (min-width: 992px) {
  .loader {
    left: 50%;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  0% {
    transform: rotate(360deg);
  }
}
