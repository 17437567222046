*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body{
  font-family: 'Poppins', sans-serif;
}

/* Srabi */
@font-face {
  font-family: "Srabi";
  src: local("Srabi"), url(./fonts/Srabi\ Script/srabiscript.ttf);
}


/*  my container class */ 
.contain {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}


@media (min-width: 640px) {
  .contain {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .contain {
    padding-left: 3rem;
    padding-right: 3rem;
    max-width: 768px;
  }
}


@media (min-width: 1024px) {
  .contain {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .contain {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .contain {
    max-width: 1536px;
  }
}
